import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _range from 'lodash/range';
import _isEqual from 'lodash/isEqual';
import cx from 'clsx';
// import UAParser from 'ua-parser-js';
// import ReactGA from 'react-ga';
import { Subject } from 'rxjs';
import dashjs, { MediaPlayer } from 'dashjs';
import imageog, { ImageogConfig, ImageogOptions } from 'imageog';

import {
  postFTVideoReadyMessage,
  postFTTimeUpdateMessage,
  postFTVideoEndedMessage,
  messageIsFTTogglePlayPause,
} from 'videog-api';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Classes, ClassName, Style } from '../types/styles';

import { getBaseUrl, getMediaStreamType, mediaStreamTypeInfos } from './utils';
import { isClickEvent, isKeypressEvent } from '../lib/utils/typeguards';

import Controls, { DEFAULT_VOLUME } from './Controls';
import { getVideoTimeInPercentage } from '../lib/utils/time';
import defaults from '../defaults';

// import PlaytimeTracker from '../lib/playtime';

// const UA = new UAParser();
// const device = UA.getDevice();
// const isMobile = device.type === 'mobile';
// TODO: Mobile detection
const isMobile = false;

// const getOrientationType = () =>
//   window &&
//   (window.screen.msOrientation ||
//     (window.screen.orientation || window.screen.mozOrientation || {}).type);
// const getOrientation = () =>
//   window && (window.screen.orientation || window.screen.mozOrientation);

declare global {
  interface HTMLDivElement {
    webkitRequestFullscreen: () => void;
  }
  interface Document {
    webkitExitFullscreen: () => void;
  }
}

const useStyles = makeStyles<
  Theme,
  {
    asCoverVertical: 'top' | 'center' | 'bottom';
    asCoverHorizontal: 'left' | 'center' | 'right';
  }
>(theme =>
  createStyles({
    // Root
    root: {
      position: 'relative',
      height: '100%',
      width: '100%',
      backgroundColor: theme.player.palette.background, // video background (letterbox/pillars)
      outline: 'none',

      // '&::after': {
      //   content: '""',
      //   position: 'absolute',
      //   top: 0,
      //   right: 0,
      //   bottom: 0,
      //   left: 0,
      //   zIndex: '-1',
      //   boxShadow: `0 0 50px -15px ${theme.player.palette.background}`,
      //   opacity: 0,
      //   transition: theme.transitions.create('opacity'),
      // },
      // '&$rootActive::after': {
      //   opacity: 1,
      // },

      // '&$rootFullscreen': {
      //   margin: '0 !important',
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'center',

      //   '& $videoWrapper': {
      //     display: 'flex',
      //   },
      // },
    },
    rootActive: {},
    rootFullscreen: {},
    hideCursor: {
      cursor: 'none',
    },

    playerWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    // Video
    videoWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%', // Mandatory for fullscreen
      overflow: 'hidden', // video might overflow by 1px
    },
    video: {
      display: 'flex', // Prevents bottom "margin"
      width: '100%',
      height: '100%',
    },
    videoCover: {
      position: 'absolute',
      objectFit: 'cover', // Bellow videoCover... classes would not be needed on objectFit compatible browsers
      objectPosition: ({ asCoverVertical, asCoverHorizontal }) =>
        `${asCoverHorizontal} ${asCoverVertical}`,
    },
    videoCoverWidth: ({ asCoverVertical }) => {
      const topPercentageMapping = { top: '0%', center: '50%', bottom: '100%' };
      return {
        width: '100%',
        height: 'auto',
        top: topPercentageMapping[asCoverVertical],
        transform: `translateY(-${topPercentageMapping[asCoverVertical]})`,
      };
    },
    videoCoverHeight: ({ asCoverHorizontal }) => {
      const leftPercentageMapping = {
        left: '0%',
        center: '50%',
        right: '100%',
      };
      return {
        width: 'auto',
        height: '100%',
        left: leftPercentageMapping[asCoverHorizontal],
        transform: `translateX(-${leftPercentageMapping[asCoverHorizontal]})`,
      };
    },
    videoOverlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      outline: 'none',
    },
    videoOverlayHidden: {
      display: 'none',
    },
  }),
);

const fullScreenToggle = (
  element: HTMLDivElement | HTMLVideoElement,
): boolean => {
  if (
    element.isSameNode(document.fullscreenElement) ||
    (typeof (document as any).webkitFullscreenElement !== 'undefined' &&
      element.isSameNode((document as any).webkitFullscreenElement)) ||
    (typeof (document as any).webkitFullScreenElement !== 'undefined' &&
      element.isSameNode((document as any).webkitFullScreenElement))
  ) {
    // exit fullscreen
    if (typeof document.exitFullscreen !== 'undefined') {
      document.exitFullscreen();
      return true;
    }
    if (typeof document.webkitExitFullscreen !== 'undefined') {
      document.webkitExitFullscreen();
      return true;
    }
    if (typeof (element as any).webkitExitFullScreen !== 'undefined') {
      (element as any).webkitExitFullScreen(
        (Element as any).ALLOW_KEYBOARD_INPUT,
      );
      return true;
    }
    return false;
  }
  if (typeof element.requestFullscreen !== 'undefined') {
    element.requestFullscreen({ navigationUI: 'auto' });
    return true;
  }
  if (
    element instanceof HTMLDivElement &&
    typeof element.webkitRequestFullscreen !== 'undefined'
  ) {
    element.webkitRequestFullscreen();
    return true;
  }
  if (typeof (element as any).webkitRequestFullScreen !== 'undefined') {
    (element as any).webkitRequestFullScreen(
      (Element as any).ALLOW_KEYBOARD_INPUT,
    );
    return true;
  }
  return false;
};

export type PlayerProps = {
  /** Used to generate the videos' URL */
  serverUrl?: string;
  /** Also called "video key" */
  id: string;
  /** Imageog config with bucket, key, etc. */
  posterImageogConfig?: ImageogConfig;
  /** Edit / resize / ... ?
   *  Set to null if not yet ready */
  posterImageogOptions?: ImageogOptions | null;
  autoplay?: boolean;
  /** Wait for Xms before autoplay starts */
  autoplayTimeout?: number;
  loop?: boolean;
  /** User won't be able to activate sound */
  forceMuted?: boolean;
  /** User will be able to activate sound */
  defaultMuted?: boolean;
  /** Prevent autoplay on dev environnement */
  noAutoplayOnDev?: boolean;
  preload?: 'none' | 'metadata' | 'auto';
  presumedDuration?: string; // videoDuration: string,
  // gaVideoEvent?: () => void; // TODO: change ?
  /** Callback triggered on video's onTimeUpdate event */
  onLoadedMetadata?: (
    event: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onTimeUpdate?: (
    event?: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => void;
  onVideoEnded?: (
    event?: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => void;
  /** Dash option */
  minBitrate?: number;
  /** To debug Dash */
  debug?: boolean;
  /** Set to true to use the video as a cover, no control, full width, cropped to fill */
  asCover?: boolean;
  /** On cover, tune the cropping vertical position */
  asCoverVertical?: 'top' | 'center' | 'bottom';
  /** On cover, tune the cropping horizontal position */
  asCoverHorizontal?: 'left' | 'center' | 'right';
  // Styles
  style?: Style;
  className?: ClassName;
  classes?: Classes;
  // TODO: Remove in favor of classNames.video
  videoStyle?: Style; // used by sync player to scale
};

export type ForwardedPlayerRef = {
  getCurrentTime: () => HTMLVideoElement['currentTime'] | undefined;
  setCurrentTime: (time: HTMLVideoElement['currentTime']) => void;
  getDuration: () => number | undefined;
  play: () => void;
  pause: () => void;
  isPlaying$: Subject<boolean>;
  currentTime$: Subject<number>;
};
const Player = React.forwardRef<ForwardedPlayerRef, PlayerProps>(
  (
    {
      serverUrl = defaults.serverUrl,
      id,
      posterImageogConfig,
      posterImageogOptions,
      autoplay: autoplayRaw = undefined,
      autoplayTimeout = 0,
      noAutoplayOnDev = false,
      preload = 'metadata',
      loop: loopRaw = undefined,
      forceMuted = false,
      defaultMuted = false,
      presumedDuration = '',
      // gaVideoEvent = _.noop,
      onLoadedMetadata = () => {},
      onPlay = () => {},
      onPause = () => {},
      onTimeUpdate = () => {},
      onVideoEnded = () => {},
      minBitrate = undefined,
      asCover = false,
      asCoverVertical = 'center',
      asCoverHorizontal = 'center',
      debug = false,
      style = {},
      className = '',
      classes: classNames = {},
      videoStyle = {},
    }: PlayerProps,
    forwardedRef,
  ) => {
    const userCanControl = !asCover;
    const autoplay = typeof autoplayRaw === 'undefined' ? asCover : autoplayRaw;
    const loop = typeof loopRaw === 'undefined' ? asCover : loopRaw;

    const classes = useStyles({ asCoverVertical, asCoverHorizontal });

    const progress$ = useMemo(() => new Subject<number>(), []);
    const buffer$ = useMemo(() => new Subject<number>(), []);
    const currentTime$ = useMemo(() => new Subject<number>(), []);
    const isPlaying$ = useMemo(() => new Subject<boolean>(), []);

    const baseUrl = useMemo(() => getBaseUrl(serverUrl, id), [serverUrl, id]);

    const refPlayerWrapper = useRef<HTMLDivElement>(null);
    const refVideo = useRef<HTMLVideoElement>(null);

    // Stream type
    const streamType = useMemo(getMediaStreamType, []);
    const { suffix, mimeType } = mediaStreamTypeInfos[streamType];

    /** Initialize Dash */
    useLayoutEffect(() => {
      const videoEl = refVideo.current;
      if (!videoEl) {
        console.warn('No video element, dash cannot initialize.');
        return;
      }
      if (streamType === 'dash') {
        const player = MediaPlayer().create();
        player.initialize(videoEl, `${baseUrl}/${suffix}`, false);
        const streamingAbr: NonNullable<
          dashjs.MediaPlayerSettingClass['streaming']
        >['abr'] = {
          ABRStrategy: 'abrThroughput', // default: 'abrDynamic',
          useDefaultABRRules: true, // default: true
          movingAverageMethod: 'slidingWindow', // default: 'slidingWindow'
          useDeadTimeLatency: false, // default: true - see AbrController, ignores latency
          initialBitrate: {
            video: 6000,
          },
        };
        if (typeof minBitrate !== 'undefined') {
          streamingAbr.minBitrate = { video: minBitrate };
        }

        player.updateSettings({
          debug: {
            logLevel: debug
              ? dashjs.LogLevel.LOG_LEVEL_DEBUG
              : dashjs.LogLevel.LOG_LEVEL_ERROR,
          },
          streaming: {
            metricsMaxListDepth: 50, // default: 1000, ref player: 50
            fastSwitchEnabled: true, // default: false, ref player: true
            abr: streamingAbr,
          },
        });
      }
    }, [debug, streamType, baseUrl, suffix, minBitrate]);

    /** Buffer */
    const checkBuffer = useCallback(() => {
      // TODO: - use "loadmetadata" or "canplay" event (?)
      const videoEl = refVideo.current;
      if (!videoEl) {
        console.warn('No video element, buffer cannot be handled.');
        return;
      }
      const { buffered, currentTime } = videoEl;
      if (buffered.length > 0) {
        const bufferedIndex = _range(buffered.length).find(
          bIndex =>
            buffered.start(bIndex) <= currentTime &&
            buffered.end(bIndex) >= currentTime,
        );

        if (typeof bufferedIndex !== 'undefined') {
          const newBuffer = getVideoTimeInPercentage(
            videoEl,
            buffered.end(bufferedIndex),
          );
          buffer$.next(newBuffer);
        }
      }
    }, [buffer$]);
    useLayoutEffect(() => {
      const checkBufferInterval = setInterval(checkBuffer, 2000);
      return () => {
        clearInterval(checkBufferInterval);
      };
    }, [checkBuffer]);

    /** Play / pause */
    const [playing, setPlaying] = useState(false);
    const handlePlay = useCallback(() => {
      const videoEl = refVideo.current;
      if (!videoEl) {
        console.warn('No video element, cannot handle play.');
        return;
      }
      // TODO: handle fullscreen on playing on mobile ?
      // if (event && isMobile && !this.hasPlayed) this.fullScreen();
      const playPromise = videoEl.play();
      // In browsers that don’t yet support this functionality (IE11),
      // playPromise won’t be defined.
      if (playPromise !== undefined) {
        // FIXME: has .play() does not return a promise on IE11, shoud change the whole play state functionnality and base it on the Media DOM element's state
        playPromise
          .then(() => {
            // if (!this.hasPlayed) {
            //   // this.gaVideoEvent('start');
            //   // this.tracker = new PlaytimeTracker({
            //   //   element: this.refVideo.current,
            //   //   update: this.gaVideoEvent,
            //   // });
            // }
            // this.hasPlayed = true;
            // this.startCheckVideoViewed();
            setPlaying(true);
            // this.addMouseMoveEventListener();
          })
          .catch(() => {
            setPlaying(false);
            // ReactGA.exception({ description: e.message });
            // this.setState({ playing: false, showControls: true });
            // this.fullScreenExit();
          });
      } else {
        setPlaying(true);
      }
    }, []);
    const handlePause = useCallback(() => {
      const videoEl = refVideo.current;
      if (!videoEl) {
        console.warn('No video element, cannot handle pause.');
        return;
      }
      videoEl.pause();
      setPlaying(false);
    }, []);
    useEffect(() => {
      if (playing) {
        onPlay();
      } else {
        onPause();
      }
      isPlaying$.next(playing);
    }, [onPause, onPlay, playing, isPlaying$]);
    const handleTogglePlayPause = useCallback(() => {
      const videoEl = refVideo.current;
      if (!videoEl) {
        console.warn('No video element, cannot toggle play pause.');
        return;
      }
      if (!playing) {
        handlePlay();
      } else {
        handlePause();
      }
    }, [handlePause, handlePlay, playing]);

    const handleClickPressVideo = useCallback(
      (event: React.MouseEvent | React.KeyboardEvent) => {
        if (
          isClickEvent(event) ||
          (isKeypressEvent(event) && event.key === ' ')
        ) {
          event.preventDefault();
          handleTogglePlayPause();
        }
      },
      [handleTogglePlayPause],
    );
    const handleClickVideo = useCallback(
      (event: React.MouseEvent) => handleClickPressVideo(event),
      [handleClickPressVideo],
    );
    const handleKeypressVideo = useCallback(
      (event: React.KeyboardEvent) => handleClickPressVideo(event),
      [handleClickPressVideo],
    );

    /** Autoplay when canPlay event if fired */
    const refTimeoutAutoplay = useRef<number>();
    const handleVideoCanPlayEvent = useCallback(() => {
      postFTVideoReadyMessage();
      if (
        !autoplay ||
        isMobile || // don't start on mobile
        (noAutoplayOnDev && process.env.NODE_ENV === 'development')
      ) {
        return;
      }
      refTimeoutAutoplay.current = window.setTimeout(
        handlePlay,
        autoplayTimeout,
      );
    }, [autoplay, autoplayTimeout, noAutoplayOnDev, handlePlay]);
    useLayoutEffect(() => {
      const videoEl = refVideo.current;
      if (!videoEl) {
        console.warn('No video element, cannot handle autoplay.');
        return () => {};
      }
      videoEl.addEventListener('canplay', handleVideoCanPlayEvent);
      return () => {
        videoEl.removeEventListener('canplay', handleVideoCanPlayEvent);
        clearTimeout(refTimeoutAutoplay.current);
      };
    }, [handleVideoCanPlayEvent]);

    /** Navigate */
    const handleChangeSeekbarProgress = useCallback(
      (newProgressValue: number) => {
        const videoEl = refVideo.current;
        if (!videoEl) {
          console.warn('No video element, cannot handle autoplay.');
          return;
        }
        if (!Number.isFinite(videoEl.duration)) {
          console.warn(
            'Video had no duration, could not navigate video throught seekbar progress',
          );
          return;
        }
        const time = videoEl.duration * (newProgressValue / 100);
        videoEl.currentTime = time;
        // Update progress now for user experience
        // ... because timeUpdate event might occur with some delay on manual change
        const newProgress = getVideoTimeInPercentage(videoEl, time);
        progress$.next(newProgress);
      },
      [progress$],
    );

    /** Time Handlers */
    const handleVideoTimeUpdate = useCallback(
      (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        onTimeUpdate(e);
        const videoEl = refVideo.current;
        if (!videoEl) {
          console.warn('No video element, cannot handle autoplay.');
          return;
        }
        postFTTimeUpdateMessage(videoEl.currentTime);
        currentTime$.next(videoEl.currentTime);
        const newProgress = getVideoTimeInPercentage(videoEl);
        progress$.next(newProgress);
      },
      [onTimeUpdate, currentTime$, progress$],
    );
    const handleVideoEnded = useCallback(
      (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        onVideoEnded(e);
        postFTVideoEndedMessage();
        handlePause();
        progress$.next(100); // Force it, just in case
      },
      [handlePause, onVideoEnded, progress$],
    );

    /** Mute */
    const [muted, setMuted] = useState(autoplay || defaultMuted); // Initialize with autoplay as in this case it must be muted
    const unmute = useCallback(
      (videoEl: HTMLVideoElement) => {
        if (videoEl.muted && !forceMuted) {
          videoEl.muted = false;
        }
        setMuted(false);
      },
      [forceMuted],
    );
    const handleClickMuteUnmute = useCallback(() => {
      const videoEl = refVideo.current;
      if (!videoEl) {
        console.warn('No video element, cannot handle mute/unmute.');
        return;
      }
      if (!videoEl.muted) {
        videoEl.muted = true;
        setMuted(true);
      } else {
        unmute(videoEl);
      }
    }, [unmute]);
    const isMuted = useMemo(() => forceMuted || muted, [forceMuted, muted]);

    /** Volume */
    const [volume, setVolume] = useState(DEFAULT_VOLUME);
    const handleChangeVolume = useCallback(
      (newVolume: number) => {
        const videoEl = refVideo.current;
        if (!videoEl) {
          console.warn('No video element, cannot handle volume.');
          return;
        }
        unmute(videoEl);
        videoEl.volume = newVolume / 100;
        setVolume(newVolume);
      },
      [unmute],
    );

    /** Fullscreen */
    const [fullscreen, setFullscreen] = useState(false);
    const handleToggleFullscreen = useCallback(() => {
      const elPlayer = refPlayerWrapper.current;
      const elVideo = refVideo.current;
      if (elPlayer) {
        let success = fullScreenToggle(elPlayer);
        if (!success && elVideo) success = fullScreenToggle(elVideo);
        if (!success) console.warn('Could not toggle fullscreen');
      }
    }, []);
    const handleDoubleClickVideo = useCallback(() => {
      handleToggleFullscreen();
    }, [handleToggleFullscreen]);
    const handleFullscreenChange = useCallback(() => {
      const elPlayer = refPlayerWrapper.current;
      setFullscreen(
        !!elPlayer && elPlayer.isSameNode(document.fullscreenElement),
      );
    }, []);
    useLayoutEffect(() => {
      document.addEventListener('fullscreenchange', handleFullscreenChange);
      return () =>
        document.removeEventListener(
          'fullscreenchange',
          handleFullscreenChange,
        );
    }, [handleFullscreenChange]);

    /** Shortcuts */
    const handleKeyPress = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (event.key.toLowerCase()) {
          case 'f':
            handleToggleFullscreen();
            event.stopPropagation();
            break;
          case 'p':
            handleTogglePlayPause();
            event.stopPropagation();
            break;
          default:
            break;
        }
      },
      [handleToggleFullscreen, handleTogglePlayPause],
    );

    /** Display features */
    const [showControls, setShowControls] = useState(true);
    const refMousePosition = useRef<{ clientX: number; clientY: number }>({
      clientX: 0,
      clientY: 0,
    });
    const refShowControlsTimeout = useRef<number>();
    const doShowControls = useCallback(() => {
      setShowControls(true);
      clearTimeout(refShowControlsTimeout.current);
      refShowControlsTimeout.current = window.setTimeout(() => {
        setShowControls(false);
      }, 2000);
    }, []);
    useLayoutEffect(() => {
      return () => {
        clearTimeout(refShowControlsTimeout.current);
      };
    }, [handleVideoCanPlayEvent]);
    const handleMouseMove = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { clientX, clientY } = event;
        requestAnimationFrame(() => {
          const mousePosition = { clientX, clientY };
          if (!_isEqual(refMousePosition.current, mousePosition)) {
            refMousePosition.current = mousePosition;
            doShowControls();
          }
        });
      },
      [doShowControls],
    );

    const handleLoadedMetadata = useCallback(
      (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        onLoadedMetadata(event);
      },
      [onLoadedMetadata],
    );

    /** Video cover for IE */
    const [videoCoverClass, setVideoCoverClass] = useState('');
    const handleWindowResizeVideoCover = useCallback(() => {
      if (asCover) {
        requestAnimationFrame(() => {
          if (refVideo.current && refPlayerWrapper.current) {
            const { videoHeight, videoWidth } = refVideo.current;
            const { offsetHeight, offsetWidth } = refPlayerWrapper.current;
            const videoRatio = videoWidth / videoHeight;
            const wrapperRatio = offsetWidth / offsetHeight;
            setVideoCoverClass(
              videoRatio <= wrapperRatio
                ? 'videoCoverWidth'
                : 'videoCoverHeight',
            );
          }
        });
      }
    }, [asCover]);
    useLayoutEffect(() => {
      if (asCover && !('object-fit' in document.body.style)) {
        handleWindowResizeVideoCover();
        window.addEventListener('resize', handleWindowResizeVideoCover);
        return () => {
          window.removeEventListener('resize', handleWindowResizeVideoCover);
        };
      }
      return () => {};
    }, [asCover, handleWindowResizeVideoCover]);

    const thumb = useMemo(() => {
      if (posterImageogConfig && posterImageogOptions !== null) {
        return imageog(posterImageogConfig, posterImageogOptions);
      }
      return '';
    }, [posterImageogConfig, posterImageogOptions]);

    useImperativeHandle(forwardedRef, () => ({
      getCurrentTime: () => {
        const videoEl = refVideo.current;
        return videoEl?.currentTime;
      },
      setCurrentTime: (time: HTMLVideoElement['currentTime']) => {
        const videoEl = refVideo.current;
        if (!videoEl) {
          console.warn('No video element, cannot setCurrentTime.');
          return;
        }
        videoEl.currentTime = time;
      },
      getDuration: (): number | undefined => {
        const videoEl = refVideo.current;
        if (!videoEl) {
          console.warn('No video element, cannot getDuration.');
          return undefined;
        }
        return videoEl.duration;
      },
      play: (): void => {
        handlePlay();
      },
      pause: (): void => {
        handlePause();
      },
      currentTime$,
      isPlaying$,
    }));

    /** postMessages */
    const receivedMessage = useCallback(
      (evt: MessageEvent) => {
        if (messageIsFTTogglePlayPause(evt.data)) {
          handleTogglePlayPause();
        }
      },
      [handleTogglePlayPause],
    );
    useEffect(() => {
      window.addEventListener('message', receivedMessage, false);
      return () => {
        window.removeEventListener('message', receivedMessage, false);
      };
    });

    return (
      <div
        className={cx(className, classes.root, classNames.root, {
          [classes.rootActive]: playing,
          [classes.rootFullscreen]: fullscreen,
          [classes.hideCursor]: playing && !showControls,
        })}
        ref={refPlayerWrapper}
        role="button"
        tabIndex={-1}
        onKeyPress={handleKeyPress}
        style={style}
        onMouseMove={handleMouseMove}
      >
        <div className={classes.playerWrapper}>
          <div className={classes.videoWrapper}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              key={id} // if the id changes it will reset our player
              className={cx(classes.video, classNames.video, {
                [classes.videoCover]: asCover,
                [classes[videoCoverClass]]: asCover,
              })}
              style={videoStyle}
              poster={thumb}
              preload={preload}
              ref={refVideo}
              autoPlay={streamType !== 'dash' ? autoplay : undefined}
              muted={isMuted}
              onTimeUpdate={handleVideoTimeUpdate}
              onEnded={handleVideoEnded}
              // controls={isMobile && fullscreen}
              loop={loop}
              onLoadedMetadata={handleLoadedMetadata}
            >
              {streamType !== 'dash' ? (
                <source src={`${baseUrl}/${suffix}`} type={mimeType} />
              ) : null}
            </video>
            {userCanControl && (
              <div
                className={cx(classes.videoOverlay, {
                  [classes.videoOverlayHidden]: isMobile && fullscreen,
                })}
                role="button"
                tabIndex={-1}
                onClick={handleClickVideo}
                onKeyPress={handleKeypressVideo}
                onDoubleClick={handleDoubleClickVideo}
                aria-label="Click for toggle play pause or double click for fullscreen"
              />
            )}
          </div>
          {userCanControl !== false ? (
            <Controls
              refVideo={refVideo}
              hide={!userCanControl || !showControls}
              playing={playing}
              muted={isMuted}
              forceMuted={forceMuted}
              volume={volume}
              presumedDuration={presumedDuration}
              progress$={progress$}
              buffer$={buffer$}
              isMobile={isMobile}
              fullscreen={fullscreen}
              onChangeSeekbarProgress={handleChangeSeekbarProgress}
              onTogglePlayPause={handleTogglePlayPause}
              onToggleMuteUnmute={handleClickMuteUnmute}
              onChangeVolume={handleChangeVolume}
              onToggleFullscreen={handleToggleFullscreen}
            />
          ) : null}
        </div>
      </div>
    );
  },
);

export default Player;
