export function isClickEvent(
  event: React.MouseEvent | React.KeyboardEvent,
): event is React.MouseEvent {
  return event.type === 'click';
}

export function isKeypressEvent(
  event: React.MouseEvent | React.KeyboardEvent,
): event is React.KeyboardEvent {
  return event.type === 'keypress';
}
