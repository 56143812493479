import _merge from 'lodash/merge'
import memoizeOne from 'memoize-one'

import {
  ThemeOptions,
  unstable_createMuiStrictModeTheme as createMuiTheme
} from '@material-ui/core'

/**
 * Function to set the Material-UI lib theme
 * http://www.material-ui.com/#/customization/themes
 *    Load custom fonts
 *    Apply custom styles (colors mainly)
 * @return  {object}  muiTheme
 * @author Sylvain Pont
 */
const getTheme = memoizeOne((theme?: ThemeOptions) => {
  // Roboto always here as backup font
  const fonts = {
    google: {
      families: ['Roboto:300,400,500']
    }
  }
  if (typeof window !== 'undefined') {
    // For SSR
    // eslint-disable-next-line
    const WebFontLoader = require('webfontloader')
    WebFontLoader.load(fonts)
  }

  const fontFamily = 'Roboto, Helvetica, Arial, sans-serif'

  const hardWhite = 'rgb(255, 255, 255)'
  const mediumWhite = 'rgb(250, 250, 250)'
  const lightWhite = 'rgb(245, 245, 245)'
  const muiTheme = createMuiTheme(
    _merge(
      {
        palette: {
          // "Bright" color
          primary: {
            // light and dark entries, if not set, will be generated
            main: 'rgb(203, 0, 68)',
            contrastText: mediumWhite
          },
          // "Dark" color
          secondary: {
            // light and dark entries, if not set, will be generated
            main: 'rgb(62, 61, 64)',
            contrastText: mediumWhite
          },
          common: {
            lightWhite,
            mediumWhite,
            white: mediumWhite,
            hardWhite,
            black: 'rgb(22, 22, 22)',
            yammerblue: 'rgb(0, 136, 206)'
          },
          // "Whiteish" color
          background: {
            default: mediumWhite,
            paper: lightWhite,
            sncfgrey: 'rgb(224, 225, 221)'
          }
        },
        typography: { fontFamily, useNextVariants: true }
      },
      theme
    )
  )

  // window.MUI_THEME = muiTheme;

  return muiTheme
})

// eslint-disable-next-line import/prefer-default-export
export { getTheme }
