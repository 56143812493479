export function getBaseUrl(serverUrl: string, id: string): string {
  return `${serverUrl}/${id.slice(0, 2)}/${id.slice(2)}`;
}

export type MediaStreamType = 'dash' | 'hls' | 'mp4';
export type MediaStreamTypeInfos = {
  [k in MediaStreamType]: {
    suffix: string;
    mimeType: string;
  };
};

export function getMediaStreamType(): MediaStreamType {
  if (
    {}.hasOwnProperty.call(window, 'MediaSource') ||
    {}.hasOwnProperty.call(window, 'WebkitMediaSource')
  ) {
    return 'dash';
  }
  const video = document.createElement('video');
  if (video.canPlayType('application/vnd.apple.mpegURL')) {
    return 'hls';
  }
  return 'mp4';
}

export const mediaStreamTypeInfos: MediaStreamTypeInfos = {
  dash: {
    suffix: 'dash/stream.mpd',
    mimeType: 'application/dash+xml',
  },
  hls: {
    suffix: 'hls/master.m3u8',
    mimeType: 'application/vnd.apple.mpegURL',
  },
  mp4: {
    suffix: 'media.mp4',
    mimeType: 'video/mp4',
  },
};
