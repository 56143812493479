import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import cx from 'clsx';
import { Subject } from 'rxjs';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  getVideoCurrentTimeFromPercentage,
  secondsToVideoDisplayTime,
} from '../lib/utils/time';

export const PROGRESSBAR_SENSIBILITY_PADDING = 6;
export const PROGRESSBAR_HEIGHT = 6;
export const PROGRESSBAR_SCALE = 0.5;

const useStyles = makeStyles<Theme, { over: number }>(theme => {
  const sliderBarContained = {
    position: 'absolute',
    top: PROGRESSBAR_SENSIBILITY_PADDING,
    right: 0,
    bottom: PROGRESSBAR_SENSIBILITY_PADDING,
    left: 0,
    transition: 'width linear .25s',
    cursor: 'pointer',
  } as const;
  return createStyles({
    root: {
      position: 'relative',
      height: PROGRESSBAR_HEIGHT,
      padding: `${PROGRESSBAR_SENSIBILITY_PADDING}px 0 `, // For mouse over sensibility: user can go further
      boxSizing: 'content-box',
      transform: `scaleY(${PROGRESSBAR_SCALE})`,
      outline: 'none',
      cursor: 'pointer',
      transition: 'transform ease .1s',
      '&:hover': {
        transform: 'scaleY(1)',
      },

      '&:hover$deactivated': {
        filter: 'none',
        opacity: 1,
      },

      '&:hover $background': {
        backgroundColor: theme.player.palette.progress.background,
      },

      '&:hover $over': {
        opacity: 1,
      },

      // On mouse hover progressed part
      '&:hover $over$overProgress': {
        backgroundColor: theme.player.palette.progress.primary,
        zIndex: 1,
      },

      // When mouse hover progressed part => progress
      '&:hover $progress$underOver': {
        backgroundColor: fade(theme.player.palette.progress.primary, 0.5),
        zIndex: 1,
      },
    },
    deactivated: {
      filter: 'grayscale(100%)',
      opacity: 0.3,
    },
    background: {
      ...sliderBarContained,
      backgroundColor: fade(theme.player.palette.progress.background, 0.5),
    },
    buffer: {
      ...sliderBarContained,
      backgroundColor: fade(theme.player.palette.progress.buffer, 0.3),
    },
    over: {
      ...sliderBarContained,
      backgroundColor: fade(theme.player.palette.progress.over, 0.3),
      opacity: 0,
      transition: 'none',
    },
    overProgress: {},
    overTooltip: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -7,
        right: -5,
        height: 0,
        width: 0,
        border: `${5}px solid transparent`,
        borderTopColor: fade(theme.player.palette.tooltip.background, 0.8),
      },
      '&::after': {
        content: 'attr(data-current)',
        position: 'absolute',
        top: -37,
        height: 30,
        right: ({ over }) => -50 + (40 * over) / 100,
        width: 60,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 15,
        lineHeight: 1,
        backgroundColor: fade(theme.player.palette.tooltip.background, 0.8),
        color: theme.player.palette.tooltip.contrastText,
        borderRadius: theme.shape.borderRadius,
      },
    },
    progress: {
      ...sliderBarContained,
      backgroundColor: theme.player.palette.progress.primary,
    },
    underOver: {},
  });
});

type ProgressBarProps = {
  onChange: (n: number) => void;
  withTooltip?: boolean;
  deactivated?: boolean;
  className?: string;
  refVideo?: React.RefObject<HTMLVideoElement>;
  progress$?: Subject<number>;
  buffer$?: Subject<number>;
  forcedProgress?: any;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  refVideo,
  progress$,
  buffer$,
  forcedProgress,
  onChange,
  withTooltip = false,
  deactivated = false,
  className = '',
}: ProgressBarProps) => {
  const refRoot = useRef<HTMLDivElement>(null);

  const [over, setOver] = useState(0);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);

  const handleProgressChanged = useCallback((p$: number) => {
    setProgress(p$);
  }, []);
  useEffect(() => {
    if (typeof progress$ !== 'undefined') {
      const progressSubscription = progress$.subscribe(handleProgressChanged);
      return () => progressSubscription.unsubscribe();
    }
    return () => {};
  }, [forcedProgress, handleProgressChanged, progress$]);
  const handleBufferChanged = useCallback((b$: number) => {
    setBuffer(b$);
  }, []);
  useEffect(() => {
    if (typeof buffer$ !== 'undefined') {
      const bufferSubscription = buffer$.subscribe(handleBufferChanged);
      return () => bufferSubscription.unsubscribe();
    }
    return () => {};
  }, [handleBufferChanged, buffer$]);

  const handleClick = useCallback(
    (event: any) => {
      event.stopPropagation();
      const { clientX } = event;
      requestAnimationFrame(() => {
        const elRoot = refRoot.current;
        if (elRoot) {
          const { left, width } = elRoot.getBoundingClientRect();
          const newSeekbarValue = ((clientX - left) / width) * 100;
          onChange(newSeekbarValue);
        }
      });
    },
    [onChange],
  );

  const handleMouseMouse = useCallback((event: any) => {
    const { clientX } = event;
    requestAnimationFrame(() => {
      const elRoot = refRoot.current;
      if (elRoot) {
        const { left, width } = elRoot.getBoundingClientRect();
        const overValue = ((clientX - left) / width) * 100;
        setOver(overValue);
      }
    });
  }, []);

  const overDisplayTime = useMemo(
    () =>
      secondsToVideoDisplayTime(
        refVideo
          ? getVideoCurrentTimeFromPercentage(refVideo.current, over)
          : 0,
      ),
    [refVideo, over],
  );

  const p = typeof forcedProgress === 'undefined' ? progress : forcedProgress;

  const classes = useStyles({ over });

  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={handleClick}
      onKeyPress={_.noop}
      onMouseMove={handleMouseMouse}
      ref={refRoot}
      className={cx(className, classes.root, {
        [classes.deactivated]: deactivated,
      })}
    >
      <div className={classes.background} />
      {buffer > 0 && (
        <div style={{ width: `${buffer}%` }} className={classes.buffer} />
      )}
      <div
        data-current={overDisplayTime}
        style={{ width: `${over}%` }}
        className={cx(classes.over, {
          [classes.overProgress]: over < p,
          [classes.overTooltip]: withTooltip,
        })}
      />
      <div
        style={{ width: `${p}%` }}
        className={cx(classes.progress, {
          [classes.underOver]: over < p,
        })}
      />
    </div>
  );
};
ProgressBar.defaultProps = {
  refVideo: undefined,
  progress$: undefined,
  buffer$: undefined,
  forcedProgress: undefined,
  withTooltip: undefined,
  deactivated: undefined,
  className: undefined,
};

export default ProgressBar;
