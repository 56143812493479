import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import cx from 'clsx';
import { Subject } from 'rxjs';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import Typography from '@material-ui/core/Typography';

import ProgressBar, {
  PROGRESSBAR_SENSIBILITY_PADDING,
  PROGRESSBAR_HEIGHT,
  PROGRESSBAR_SCALE,
} from './ProgressBar';

import {
  getVideoCurrentTime,
  getVideoDuration,
  secondsToVideoDisplayTime,
} from '../lib/utils/time';

export const DEFAULT_VOLUME = 75;

const useStyles = makeStyles<Theme>(theme => {
  const controlsVisible = {
    maxHeight: 40,
    opacity: 1,
  };

  return createStyles({
    controlsWrapper: {
      // This is the hidden controls
      position: 'absolute',
      top: 'unset',
      left: 0,
      bottom: 0,
      right: 0,
      maxHeight: PROGRESSBAR_HEIGHT * PROGRESSBAR_SCALE,
      opacity: 0.5,
      transition: theme.transitions.create(['max-height', 'opacity']),
      // Force controls to stay visible on hovery
      '&:hover': {
        ...controlsVisible,
        cursor: 'default',
      },
    },
    // FIXME: Only on root:hover
    controlsWrapperShown: {
      ...controlsVisible,
    },
    controlsDisablyHidden: {
      display: 'none',
    },
    controls: {
      display: 'flex',
      flexFlow: 'column nowrap',
      backgroundColor: fade(theme.player.palette.controls.background, 0.6),
      color: theme.player.palette.controls.contrastText,
    },

    // Seekbar
    seekbar: {
      // scale(0.5)               => There is a blank of `height * scale`
      // transform origin: center => We want only the top part of it: `/ 2`
      top: -(
        PROGRESSBAR_SENSIBILITY_PADDING +
        (PROGRESSBAR_HEIGHT * PROGRESSBAR_SCALE) / 2
      ),

      // The whole place taken is padding * 2 + height
      // we want only to see the scaled height, without the padding
      // So go up (negative marginBottom) by the whole then go down by scaled height
      marginBottom:
        -(
          PROGRESSBAR_SENSIBILITY_PADDING * 2 + // Remove whole seekbar (paddings + height)
          PROGRESSBAR_HEIGHT
        ) +
        PROGRESSBAR_HEIGHT * PROGRESSBAR_SCALE, // Show only scaled height
    },

    // Control buttons
    controlButtons: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.2em 0.5em',
    },
    controlButtonsLeft: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    controlButtonsRight: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    playPause: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1em',
      outline: 'none',
      cursor: 'pointer',
    },
    mute: {
      display: 'flex',
      alignItems: 'center',
      outline: 'none',
      cursor: 'pointer',
    },
    volume: {
      width: 80,
      marginLeft: '0.5em',
      marginRight: '1em',
    },
    fullscreen: {
      display: 'flex',
      alignItems: 'center',
      outline: 'none',
      cursor: 'pointer',
      transform: 'scale(1)',
      transition: theme.transitions.create('transform'),

      '&:hover': {
        transform: 'scale(1.1)',
      },
      '&$fullscreenActive:hover': {
        transform: 'scale(0.9)',
      },
    },
    fullscreenActive: {},
  });
});

type ControlsProps = {
  refVideo: React.RefObject<HTMLVideoElement>;
  presumedDuration: string;
  hide?: boolean;
  playing?: boolean;
  muted?: boolean;
  forceMuted?: boolean;
  volume?: number;
  progress$: Subject<number>;
  buffer$: Subject<number>;
  isMobile?: boolean;
  fullscreen?: boolean;
  onChangeSeekbarProgress: (n: number) => void;
  onTogglePlayPause: () => void;
  onToggleMuteUnmute: () => void;
  onChangeVolume: (n: number) => void;
  onToggleFullscreen: () => void;
};

const Controls: React.FC<ControlsProps> = ({
  refVideo,
  presumedDuration,
  hide = false,
  playing = false,
  muted = false,
  forceMuted = false,
  volume = DEFAULT_VOLUME,
  progress$,
  buffer$,
  isMobile = false,
  fullscreen = false,
  onChangeSeekbarProgress,
  onTogglePlayPause,
  onToggleMuteUnmute,
  onChangeVolume,
  onToggleFullscreen,
}: ControlsProps) => {
  const classes = useStyles();

  /** Duration */
  const [durationVideoTime, setDurationVideoTime] = useState(''); // TODO: getVideoDuration
  const handleVideoCanPlay = useCallback(() => {
    const videoDuration = getVideoDuration(refVideo.current, presumedDuration);
    setDurationVideoTime(secondsToVideoDisplayTime(videoDuration));
  }, [presumedDuration, refVideo]);
  useLayoutEffect(() => {
    const elVideo = refVideo.current;
    if (!elVideo) {
      console.warn('No video element, duration cannot initialize.');
      return () => {};
    }
    elVideo.addEventListener('canplay', handleVideoCanPlay);
    return () => {
      elVideo.removeEventListener('canplay', handleVideoCanPlay);
    };
  }, [handleVideoCanPlay, refVideo]);

  /** Progress */
  const [currentVideoTime, setCurrentVideoTime] = useState(
    secondsToVideoDisplayTime(0),
  );
  const handleProgressChanged = useCallback(() => {
    const videoCurrentTime = getVideoCurrentTime(refVideo.current);
    setCurrentVideoTime(secondsToVideoDisplayTime(videoCurrentTime));
  }, [refVideo]);
  useEffect(() => {
    const progressSubscription = progress$.subscribe(handleProgressChanged);
    handleProgressChanged();
    return () => progressSubscription.unsubscribe();
  }, [handleProgressChanged, progress$]);

  const handleClickPlayPause = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      onTogglePlayPause();
    },
    [onTogglePlayPause],
  );

  const handleClickMuteUnmute = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      if (!forceMuted) {
        onToggleMuteUnmute();
      }
    },
    [forceMuted, onToggleMuteUnmute],
  );

  const handleClickFullscreen = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      onToggleFullscreen();
    },
    [onToggleFullscreen],
  );

  const handleChangeSeekbarProgress = useCallback(
    (newProgress: number) => onChangeSeekbarProgress(newProgress),
    [onChangeSeekbarProgress],
  );

  const handleChangeVolumeProgress = useCallback(
    (newProgress: number) => onChangeVolume(newProgress),
    [onChangeVolume],
  );

  const VolumeLevelIcon = useMemo(() => {
    if (volume > 50) return VolumeUpIcon;
    if (volume > 0) return VolumeDownIcon;
    return VolumeMuteIcon;
  }, [volume]);

  return (
    <div
      className={cx(classes.controlsWrapper, {
        [classes.controlsWrapperShown]: !hide || !playing,
        [classes.controlsDisablyHidden]: isMobile && fullscreen,
      })}
    >
      <div className={classes.controls}>
        <ProgressBar
          refVideo={refVideo}
          progress$={progress$}
          buffer$={buffer$}
          onChange={handleChangeSeekbarProgress}
          className={classes.seekbar}
          withTooltip
        />
        <div className={classes.controlButtons}>
          <div className={classes.controlButtonsLeft}>
            <div
              role="button"
              tabIndex={-1}
              onClick={handleClickPlayPause}
              onKeyPress={handleClickPlayPause}
              className={classes.playPause}
            >
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </div>
            <div>
              <Typography variant="body2" color="inherit">
                {currentVideoTime} / {durationVideoTime}
              </Typography>
            </div>
          </div>
          <div className={classes.controlButtonsRight}>
            <div
              role="button"
              tabIndex={-1}
              onClick={handleClickMuteUnmute}
              onKeyPress={handleClickMuteUnmute}
              className={classes.mute}
            >
              {muted ? <VolumeOffIcon /> : <VolumeLevelIcon />}
            </div>
            {!forceMuted && (
              <ProgressBar
                forcedProgress={volume}
                onChange={handleChangeVolumeProgress}
                className={classes.volume}
                deactivated={muted}
              />
            )}
            <div
              role="button"
              tabIndex={-1}
              onClick={handleClickFullscreen}
              onKeyPress={handleClickFullscreen}
              className={cx(classes.fullscreen, {
                [classes.fullscreenActive]: fullscreen,
              })}
            >
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Controls.defaultProps = {
  hide: undefined,
  playing: undefined,
  muted: undefined,
  forceMuted: undefined,
  volume: undefined,
  isMobile: undefined,
  fullscreen: undefined,
};

export default Controls;
