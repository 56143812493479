import React, { useMemo } from 'react';
import _merge from 'lodash/merge';

import { MuiThemeProvider, ThemeOptions } from '@material-ui/core';

import { getTheme } from './lib/theme';

import Player, { ForwardedPlayerRef, PlayerProps } from './components/Player';

const DEFAULT_PALETTE = {
  background: 'rgb(0, 0, 0)',
  primary: 'rgb(203, 0, 68)',
  controls: {
    primary: 'rgb(62, 61, 64)',
    background: 'rgb(22, 22, 22)',
    contrastText: 'rgb(250, 250, 250)',
  },
  progress: {
    primary: 'rgb(203, 0, 68)',
    background: 'rgb(224, 225, 221)',
    buffer: 'rgb(100, 99, 102)',
    over: 'rgb(100, 99, 102)',
  },
  tooltip: {
    background: 'rgb(100, 99, 102)',
    contrastText: 'rgb(250, 250, 250)',
  },
};

type VideogPlayerProps = { theme?: ThemeOptions } & PlayerProps;

const VideogPlayer = React.forwardRef<ForwardedPlayerRef, VideogPlayerProps>(
  ({ theme, ...playerProps }: VideogPlayerProps, ref) => {
    // Material-UI
    const muiTheme = useMemo(
      () =>
        getTheme({
          player: {
            ...theme,
            palette: _merge(DEFAULT_PALETTE, theme?.palette),
          },
        }),
      [theme],
    );
    return (
      <MuiThemeProvider theme={muiTheme}>
        <Player ref={ref} {...playerProps} />
      </MuiThemeProvider>
    );
  },
);
VideogPlayer.defaultProps = {
  theme: {},
};

export default VideogPlayer;
export { ForwardedPlayerRef };
