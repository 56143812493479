import _padStart from 'lodash/padStart';

export function getVideoCurrentTime(elVideo: HTMLVideoElement | null): number {
  if (elVideo && Number.isFinite(elVideo.currentTime)) {
    return elVideo.currentTime;
  }
  return 0;
}

export function getVideoDuration(
  elVideo: HTMLVideoElement | null,
  presumedDuration?: string,
): number {
  if (elVideo && elVideo.duration > 0) {
    return elVideo.duration;
  }

  if (!presumedDuration) {
    return -1;
  }

  const splitted = presumedDuration.split("'");
  const seconds = +splitted[splitted.length - 1] || 0;
  const minutes = +splitted[splitted.length - 2] || 0;
  const hours = +splitted[splitted.length - 3] || 0;
  return hours * 60 * 60 + minutes * 60 + seconds;
}

export function getVideoTimeInPercentage(
  elVideo: HTMLVideoElement | null,
  time?: number,
): number {
  const currentTime = time || getVideoCurrentTime(elVideo);
  const duration = getVideoDuration(elVideo);
  return (100 / duration) * currentTime;
}

export function getVideoCurrentTimeFromPercentage(
  elVideo: HTMLVideoElement | null,
  percentage: number,
): number {
  const duration = getVideoDuration(elVideo);
  return duration > 0 ? duration * (percentage / 100) : 0;
}

const padTime = (n: number | string) => _padStart(`${n}`, 2, '0');

export function secondsToVideoDisplayTime(seconds: number): string {
  if (seconds < 0) {
    return '-:--';
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${padTime(remainingSeconds)}`;
}

export function minutesToHHMM(minutes: number): string {
  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);
  return `${padTime(h)}:${padTime(m)}`;
}
